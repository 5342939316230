*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

input[type="file"]{
  display: none;
}

.image-picker {
  border: 2px solid red;
  cursor: pointer;
} 

.container-drag {
  text-align: center;
}

.wip {
  position: absolute;
  width: 150px;
  height: 100vh;
  left: 0;
  top: 10;
  background-color: #EEEEEE;
  border-right: 1px dotted;
}

.header {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: #E0E0E0;
  width:100%;
}

.task-header {
  display: inline-block;
  background-color: skyblue;
  width:100%;
}

.droppable {
  position: absolute;
  width: 150px;
  height: 100vh;
  right: 0;
  top: 10;
  background-color: #9E9E9E;
  border-left: 1px dotted;
}

.draggable {
  width: 100px;
  height: 100px;
  background-color: yellow;
  margin: 5px auto;
  line-height: 5em;
}

.img-container{
  position: relative;
}

.unselected-img-container {
  width: 200px;
  position: relative;
  transition: all 300ms;
}

.selected-img-container {
  width: 200px;
  position: relative;
  padding: 10px 20px;
  background-color: #E1E8EE;
  transition: background-color 300ms , padding 300ms;
}

.close-icon {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
}

.unselect-check-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px;
  cursor: pointer;
  color: #DCDCDC;
}

.select-check-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px;
  cursor: pointer;
  color: #2E8B57;
}

.unselect-check-icon:hover {
  color: #808080;
}

.select-check-icon:hover {
  color: 	#008000;
}

.category-image-display {
  width: 100%;
}

@media (min-width:720px) {
  .category-image-display {
    width: 50%;
  }
}

.category-image-display {
  width: 100%;
}

@media (min-width:720px) {
  .category-image-display {
    width: 50%;
  }
}

.category-container {
  width:60%;
  /* height: 250px; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.category-image-container {
  position: relative;
}

.category-image img {
  width: 320px;
  height: 100%;
}

.category-image-container p {
  position: absolute;
  bottom: 0;
  left: 40%;
}

.product-image-container {
  position: relative;
}

.icon-button-container {
  display: none;
}

.product-image-container:hover .icon-button-container {
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 6;
}

/* modal stylimg */

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba((0), 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 500px;
  background-color: #fff;
}

.modal-header, .modal-footer {
  padding: 10px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

/* cart  */

.shopping-cart {
  width: 750px;
  height: 423px;
  margin: 80px auto;
  background: #FFFFFF;
  box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.10);
  border-radius: 6px;
 
  display: flex;
  flex-direction: column;
}

.title {
  height: 60px;
  border-bottom: 1px solid #E1E8EE;
  padding: 20px 30px;
  color: #5E6977;
  font-size: 18px;
  font-weight: 400;
}
 
.item {
  padding: 20px 30px;
  height: 120px;
  display: flex;
}
 
.item:nth-child(3) {
  border-top:  1px solid #E1E8EE;
  border-bottom:  1px solid #E1E8EE;
}

	
.buttons {
  position: relative;
  padding-top: 30px;
  margin-right: 60px;
}
.delete-btn,
.like-btn {
  display: inline-block;
  Cursor: pointer;
}
.delete-btn {
  width: 18px;
  height: 17px;
  /* background: url(&amp;quot;delete-icn.svg&amp;quot;) no-repeat center; */
}
 
.like-btn {
  position: absolute;
  top: 9px;
  left: 15px;
  /* background: url('twitter-heart.png'); */
  width: 60px;
  height: 60px;
  background-size: 2900%;
  background-repeat: no-repeat;
}

.is-active {
  animation-name: animate;
  animation-duration: .8s;
  animation-iteration-count: 1;
  animation-timing-function: steps(28);
  animation-fill-mode: forwards;
}
 
@keyframes animate {
  0%   { background-position: left;  }
  50%  { background-position: right; }
  100% { background-position: right; }
}

	
.image {
  margin-right: 50px;
}
 
/* Let’s add some basic style to  product name and description. */
.description {
  padding-top: 10px;
  margin-right: 60px;
  width: 115px;
}
 
.description span {
  display: block;
  font-size: 14px;
  color: #43484D;
  font-weight: 400;
}
 
.description span:first-child {
  margin-bottom: 5px;
}
.description span:last-child {
  font-weight: 300;
  margin-top: 8px;
  color: #86939E;
}

.quantity {
  padding-top: 20px;
  margin-right: 60px;
}
.quantity input {
  appearance: none;
  border: none;
  text-align: center;
  width: 32px;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
}
 
button[class*=btn] {
  width: 30px;
  height: 30px;
  background-color: #E1E8EE;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
.minus-btn img {
  margin-bottom: 3px;
}
.plus-btn img {
  margin-top: 2px;
}
 
button:focus,
input:focus {
  outline:0;
}

.total-price {
  width: 83px;
  padding-top: 27px;
  text-align: center;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
}

@media (max-width: 800px) {
  .shopping-cart {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .item {
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image img {
    width: 50%;
  }
  .image,
  .quantity,
  .description {
    width: 100%;
    text-align: center;
    margin: 6px 0;
  }
  .buttons {
    margin-right: 20px;
  }
}

/* credit card */

.credit-card {
  width: 360px;
  height: 400px;
  margin: 60px auto 0;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 1px 2px 3px 0 rgba(0,0,0,.10);
}

.form-header {
  height: 60px;
  padding: 20px 30px 0;
  border-bottom: 1px solid #e1e8ee;
}

.form-body {
  height: 340px;
  padding: 30px 30px 20px;
}

.title {
  font-size: 18px;
  margin: 0;
  color: #5e6977;
}

.card-number,
.cvv-input input,
.month select,
.year select {
    font-size: 14px;
    font-weight: 100;
    line-height: 14px;
}
 
.card-number,
.month select,
.year select {
    font-size: 14px;
    font-weight: 100;
    line-height: 14px;
}
 
.card-number,
.cvv-details,
.cvv-input input,
.month select,
.year select {
    opacity: .7;
    color: #86939e;
}

/* Card Number */
.card-number {
  width: 100%;
  margin-bottom: 20px;
  padding-left: 20px;
  border: 2px solid #e1e8ee;
  border-radius: 6px;
}

.month select,
.year select {
    width: 145px;
    margin-bottom: 20px;
    padding-left: 20px;
    border: 2px solid #e1e8ee;
    border-radius: 6px;
    /* background: url('caret.png') no-repeat; */
    background-position: 85% 50%;
    appearance: none;
}
 
.month select {
    float: left;
}
 
.year select {
    float: right;
}

.cvv-input input {
  float: left;
  width: 145px;
  padding-left: 20px;
  border: 2px solid #e1e8ee;
  border-radius: 6px;
  background: #fff;
}

.cvv-details {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  float: right;
  margin-bottom: 20px;
}

.cvv-details p {
  margin-top: 6px;
}

.paypal-btn,
.proceed-btn {
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    border-color: transparent;
    border-radius: 6px;
}
 
.proceed-btn {
    margin-bottom: 10px;
    background: #7dc855;
}
 
.paypal-btn a,
.proceed-btn a {
    text-decoration: none;
}
 
.proceed-btn a {
    color: #fff;
}
 
.paypal-btn a {
    color: rgba(242, 242, 242, .7);
}
 
.paypal-btn {
  padding-right: 95px;
  /* background: url('paypal-logo.svg') no-repeat 65% 56% #009cde; */
}

.cvv-input input {
  float: left;
  width: 145px;
  padding-left: 20px;
  border: 2px solid #e1e8ee;
  border-radius: 6px;
  background: #fff;
}

.cvv-details {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  float: right;
  margin-bottom: 20px;
}

.cvv-details p {
  margin-top: 6px;
}

/* card with credit */

.credit-card{
  max-width: 600px;
  /* height: 200px; */
  position: relative;
  background: linear-gradient(to top right, rgb(11,63,87), rgb(78,68,68));
  box-shadow: 1px 20px 50px grey;
  border-radius: 5px;
  padding: 1em;
  z-index: 2;
}

.credit-card .top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1{
  font-size: 32px;
  text-transform: uppercase;
}
h2{
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h3{
  position: absolute;
  bottom: -35px;
  left: 30px;
  font-size: 160px;
  text-transform: uppercase;
  color: rgb(104,101,101);
  opacity: 0.1;
  z-index: -1;
}

.credir-card .mid{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.credit-card .mid .card-number span{
  border: 1px solid grey;
  padding: 3px 5px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 2px;
}

.card-number span + span{
  margin: 8px 15px;
}

.credit-card .bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.credit-card .bottom span{
  display: inline-block;
  border: 1px solid grey;
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 14px;
  margin-top: 5px;
  text-transform: uppercase;
}

.credit-card .bottom .card-holder span{
  width: 150px;
  height: 22px;
}
.bottom span + span{
  margin-left: 5px;
}


/* 
ATMCARD 
*/

.atm-card{
  height: 310px;
  width: 500px;
  background-image:linear-gradient(rgba(255, 61, 61, 1),rgba(255, 61, 61, 0.8));
  border-radius: 20px;
  color:white;
  margin:20px;
  font-size: 16px;
  box-shadow: 2px 2px 20px #707070;
}

.atm-card-create {
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-block{
  display: inline-block;
  width:500px;
  height:155px;
}
.bottom-block{
  display: inline-block;
  width:500px;
  /* height:155px; */
}
.atm-card-chip{
  float:left;
  margin: 20px;
}
.atm-card-name{
  float:right;
  margin:20px;
  position:relative;
  font-size: 28px;
  font-weight:550;
}
.atm-card-number{
  font-size: 28px;
  margin: -15px 0 0 0;
  text-align-last: center; 
}
.balance{
  float:left;
  margin: 20px;
  /* position:relative;
  top:38px; */
}
.atm-card-balance{
  font-weight:700;
  font-size: 30px;
  margin-top:10px;
}
.atm-card-icon{
  float:right;
  margin: 0 20px 0 0;
  margin-top:75px;
}
.atm-circle-left{
  margin-right: -15px;
  opacity:0.7;
}

/* 
  Dropdown for navbar
*/

/* .dropdown {
  float: left;
  overflow: hidden;
} */

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: red;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  right: 10px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10;
  /* margin-right: 100px; */
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.category-image-picker {
  border: 2px solid #101010;
  width: 100%;
  display: block;
}

.file-input-img {
  border: 2px dashed  #101010;
  height: 200px;
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image-input {
  border: 2px dashed #101010;
  padding: 4px 8px;
  margin: 20px 0;
}

.icon-plus {
  margin: 0 auto;
}

.carousal-images-picker-label {
  border: 2px solid #101010;
  width: 100%;
  display: block;
  cursor: pointer;
}

.file-input-img {
  border: 2px dashed  #101010;
  height: 200px;
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-plus {
  margin: 0 auto;
}
/* carousal images picker styling */

.carousal-images-picker-label {
  border: 2px solid #101010;
  width: 100%;
  display: block;
  cursor: pointer;
}

input#carousal-images-picker {
  display: none;
}

.carousel-root {
  outline: none;
  width: 100% !important;
}

.category-image-picker {
  border: 2px solid #101010;
  width: 100%;
  display: block;
}

.file-input-img {
  border: 2px dashed  #101010;
  height: 200px;
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-plus {
  margin: 0 auto;
}


.page-container {
  position: relative;
  min-height: 100vh;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
}