
.product-images-slider  .swiper-slide {
    padding-top: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    
}

.product-images-slider .swiper-slide img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.product-images-slider  .swiper-button-prev {
    left: 0;
    color: #000;
}

.product-images-slider  .swiper-button-next {
    right: 0;
    color: #000;
}


.product-images-slider-thumbs .swiper-slide {
    cursor: pointer;
    border: 1px solid #dddbdb;

    
}

.siwper-slide-thumb-active {
    border-color: #ff0000;
}

.product-images-slider-thumbs-wrapper {
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    position: relative;

    
}

.product-images-slider-thumbs img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}